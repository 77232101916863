import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { Row, Container, Col } from 'reactstrap'
import { getUserAssessmentResults } from '../../redux/actions'
import AssessmentReportHeader from '../../components/Report/AssessmentReportHeader'
import AssessmentReportGrading from '../../components/Report/AssessmentReportGrading'
import AssessmentReportFooter from '../../components/Report/AssessmentReportFooter'
import SectionResult from '../../components/Report/SectionResult'
import { assessmentReportClasses } from '../../utils/bootstrapClassNames'

const AssessmentReport = (props) => {
  const { id = '', userID = '', token = '' } = useParams()
  const dispatch = useDispatch()

  // Local state for user assessment results from redux on pdf results
  // State is set on useEffect bellow if params are present from pdf endpoint to allow them to load properly
  const [pageAssessment, setPageAssessment] = useState(null)

  // Check if id and token exist from params to get report data for pdf screen grab
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentAssessment = await dispatch(getUserAssessmentResults(userID, id, () => { }, () => { }, token))

        setPageAssessment(currentAssessment)
      } catch (error) {
        console.error(error)
      }
    }

    if (token && id) fetchData()
  }, [dispatch, token, id, userID])

  return (
    <>
      <Container fluid>
        {pageAssessment &&
          <>
            <Col>
              {/* Assessment Report Header */}
              <AssessmentReportHeader
                pageAssessment={pageAssessment}
                disableHeaderButtons
              />
              {/* Assessment Report Grading Chart */}
              <AssessmentReportGrading />
              <div className={assessmentReportClasses.sectionsContainer} id='sections-container'>
                <Row>
                  <Col className='flex-grow-1'>
                    {pageAssessment?.sections
                      .map((section, index) =>
                        <SectionResult
                          key={section.id}
                          index={index + 1}
                          disableContact
                          forceOpenAllSections
                          section={section}
                          sectionScoreObj={pageAssessment?.sectionScores.find(x => x.sectionID === section.id)}
                        />
                      )}
                  </Col>
                </Row>
              </div>
            </Col>
          </>}
      </Container>
      <AssessmentReportFooter />
    </>
  )
}

export default withRouter(AssessmentReport)
