import React from 'react'
import { Col, Button, Card, CardTitle, CardBody, Collapse } from 'reactstrap'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { utils } from '../../utils/utils'
import HowToImproveScore from './HowToImproveScore'
import QuestionAnswer from './QuestionAnswer'
import QuestionFeedbackBox from './QuestionFeedbackBox'
import _ from 'lodash'
import { assessmentSectionResultsClasses } from '../../utils/bootstrapClassNames'

const SectionResult = (props) => {
  const {
    disableContact = false, forceOpenAllSections = false, section, sectionScoreObj, index,
    toggleSectionID, setToggleSectionID = () => { }, onTalkToAnExpert = () => { }, informationModal = {},
    setInformationModal = () => { }, hideUserResponses = false, hideImprovementFeedback = false
  } = props

  const { id, name, summary, shortName, callToAction, sectionQuestions = [], actionItems = [] } = section
  const { sectionScore } = sectionScoreObj
  const selectedSection = Boolean(toggleSectionID === id)

  const isSectionOpen = Boolean(selectedSection || forceOpenAllSections)

  return (
    <div id={`section-${id}`} style={{ pageBreakInside: 'avoid' }}>
      <Card className='border bg-light'>
        <CardTitle className={assessmentSectionResultsClasses.sectionContainer}>
          <span className='px-3 py-1'>{`${index ? `${index}. ` : ''}${name}`}</span>
          <span className={assessmentSectionResultsClasses.cardTitleContainer}>
            <span className={`${assessmentSectionResultsClasses.cardTitleScore} border-${utils.getScoreBorderColor(sectionScore * 100)}`} style={{ backgroundColor: utils.getScoreBgColor(sectionScore * 100) }}>{`${Math.round(Math.max(sectionScore, 0) * 100)}%`}</span>
            <span className='px-4 py-1'>
              {!forceOpenAllSections &&
                <FontAwesomeIcon onClick={() => setToggleSectionID(id)} icon={faChevronDown} size='lg' style={{ cursor: 'pointer', fontSize: '1.5em', visibility: selectedSection ? 'hidden' : '' }} />}
            </span>
          </span>
        </CardTitle>
        <Collapse isOpen={isSectionOpen}>
          <CardBody className='py-4'>
            <div className={assessmentSectionResultsClasses.cardBodyContainer}>
              <Col className={assessmentSectionResultsClasses.improveScoreContainer}>
                <h5 className='font-600 mb-4'>Summary</h5>
                <span>{summary || 'No information to show'}</span>
                {!hideImprovementFeedback && <HowToImproveScore actionItems={actionItems} />}
              </Col>
              <Col className={assessmentSectionResultsClasses.contactContainer} style={{ height: 'fit-content' }}>
                <div>
                  <span className='font-600'>{shortName}</span>
                  <p className='mt-2'>{callToAction}</p>
                </div>
                {!disableContact &&
                  <div className='d-flex justify-content-center'>
                    <Button hidden color='white' size='sm' className={assessmentSectionResultsClasses.contactButton}>
                      Watch a Video
                    </Button>
                    <Button onClick={() => onTalkToAnExpert({ sectionID: section.id })} color='primary' size='sm' className='col-6 ml-2 rounded'>
                      Talk to an Expert
                    </Button>
                  </div>}
              </Col>
            </div>
            {!hideUserResponses &&
              <div className={assessmentSectionResultsClasses.responseContainer}>
                <div className='m-2 text-uppercase'>Your Responses</div>
                {
                  _.orderBy(sectionQuestions, (q) => q.questionOrder)
                    .map(questionObj => {
                      const { question, questionID, userSelectedOption, questionOptions = [] } = questionObj
                      const selectedObj = questionOptions.find(x => x.questionOptionID === userSelectedOption)

                      return (
                        <div id={`question-card-${questionID}`} key={questionID} className={assessmentSectionResultsClasses.questionContainer} style={{ pageBreakInside: 'avoid' }}>
                          <Col className={assessmentSectionResultsClasses.questionAnswerContainer}>
                            <h5 className='font-600 mt-1'>{question}</h5>
                            <QuestionAnswer questionOptions={questionOptions} userSelectedOption={userSelectedOption} />
                          </Col>
                          <Col className={assessmentSectionResultsClasses.questionFeedbackContainer}>
                            <QuestionFeedbackBox
                              selectedObj={selectedObj}
                              questionObj={questionObj}
                              informationModal={informationModal}
                              setInformationModal={setInformationModal}
                              onTalkToAnExpert={onTalkToAnExpert}
                            />
                          </Col>
                        </div>
                      )
                    })
                }
              </div>}
          </CardBody>
        </Collapse>
      </Card>
    </div>
  )
}

export default SectionResult
