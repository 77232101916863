import React, { useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { usePromiseTracker } from 'react-promise-tracker'
import { Row, Container, Col } from 'reactstrap'
import queryString from 'query-string'
import SectionResult from '../../components/Report/SectionResult'
import AssessmentReportFooter from '../../components/Report/AssessmentReportFooter'
import ConfirmationModalWithInput from '../../components/ConfirmationModalWithInput'
import { shareAssessmentReportPDF, contactExpertAboutAssessment } from '../../redux/actions'
import InformationModal from '../../components/InformationModal'
import AssessmentReportHeader from '../../components/Report/AssessmentReportHeader'
import AssessmentReportGrading from '../../components/Report/AssessmentReportGrading'
import AssessmentReportSidebar from '../../components/Report/AssessmentReportSidebar'
import AssessmentSurvey from '../../components/Report/AssessmentSurvey'

import { assessmentReportClasses } from '../../utils/bootstrapClassNames'
import { loaderArea } from '../../utils/variables'

const defaultModalSettings = {
  isOpen: false,
  title: '',
  body: '',
  onClose: null
}

const AssessmentResults = (props) => {
  const parsedProps = queryString.parse(props?.location?.search)
  const { userAssessmentResults } = useSelector(state => state.userAssessment)
  const { survey = '' } = parsedProps

  // use the query params to determine if we need to display the assessment survey
  const displaySurvey = useMemo(() => { return Boolean(parseInt(survey)) }, [survey])
  // creating a specific condition for limiting results to keep conditionals clear and leave room to grow, but currently limited results only applies to when the survey is present
  const limitResults = Boolean(displaySurvey)

  // determine by if the results are limited how many sections should be displayed
  const displaySections = useMemo(() => {
    if (userAssessmentResults) {
      // if we are not limiting results, then return all sections
      if (!limitResults) { return userAssessmentResults?.sections }

      // otherwise we need to find the highest and lowest scored sections and return only those two.
      const highestAndLowest = userAssessmentResults?.sectionScores?.reduce((obj, section) => {
        const { sectionScore, sectionID } = section
        if (sectionScore > obj.highestScore) { obj = { ...obj, highestScore: sectionScore, highestID: sectionID } }
        if (sectionScore < obj.lowestScore) { obj = { ...obj, lowestScore: sectionScore, lowestID: sectionID } }
        return obj
      }, { highestScore: 0, lowestScore: 1 })

      // filter out all but the two sections we need
      const limitedSections = userAssessmentResults?.sections?.filter(obj => Boolean(obj?.id === highestAndLowest?.highestID || obj?.id === highestAndLowest?.lowestID))
      return limitedSections
    }

    return []
  }, [userAssessmentResults, limitResults])

  const dispatch = useDispatch()
  const { promiseInProgress: shareResultsLoading } = usePromiseTracker({ area: loaderArea.SHARE_ASSESSMENT_REPORT })

  const [showShareModal, setShowShareModal] = useState(false)

  // Toggle individual summary sections open or closed based on the id of the section that was clicked (set to open first section by default)
  const [toggleSectionID, setToggleSectionID] = useState(1)

  const onShareResults = async (email) => {
    if (shareResultsLoading) return
    dispatch(shareAssessmentReportPDF(userAssessmentResults?.userAssessmentID, email, fireShareSuccess))
  }

  const fireShareSuccess = () => {
    setShowShareModal(false)
  }

  const [informationModal, setInformationModal] = useState({ ...defaultModalSettings })

  const resetInfoModal = () => { setInformationModal({ ...defaultModalSettings }) }

  const fireContactSubAction = (error) => {
    let body = 'Thank you! An expert will be reaching out to you.'
    if (error) { body = 'Sorry, we were unable to send the email!' }

    setInformationModal({
      isOpen: true,
      title: 'Talk to an Expert',
      body,
      onClose: resetInfoModal()
    })
  }

  const onTalkToAnExpert = (info) => { dispatch(contactExpertAboutAssessment(info, fireContactSubAction)) }

  const unlockResults = () => {
    const currentPath = props?.location?.pathname
    props.history.replace({ pathname: currentPath, search: '' })
  }

  return (
    <>
      <Container fluid>
        {userAssessmentResults &&
          <>
            <Col className={assessmentReportClasses.flexColumn}>
              <AssessmentReportHeader
                pageAssessment={userAssessmentResults}
                setShowShareModal={setShowShareModal}
                disableHeaderButtons={limitResults}
              />

              <AssessmentReportGrading />
              <div className={assessmentReportClasses.sectionsContainer} id='sections-container'>
                <Row>
                  <AssessmentReportSidebar
                    pageAssessment={userAssessmentResults}
                    toggleSectionID={toggleSectionID}
                    onTalkToAnExpert={onTalkToAnExpert}
                    setToggleSectionID={setToggleSectionID}
                    disableSelection={limitResults}
                    disableContact={limitResults}
                  />
                  <Col className='flex-grow-1'>
                    {displaySections.map((section, index) => {
                      const sectionScoreObj = userAssessmentResults?.sectionScores.find(x => x.sectionID === section.id) ?? {}
                      return (
                        <SectionResult
                          setToggleSectionID={setToggleSectionID}
                          toggleSectionID={toggleSectionID}
                          key={section.id}
                          index={limitResults ? 0 : index + 1}
                          section={section}
                          sectionScoreObj={sectionScoreObj}
                          onTalkToAnExpert={onTalkToAnExpert}
                          informationModal={informationModal}
                          setInformationModal={setInformationModal}
                          disableContact={limitResults}
                          forceOpenAllSections={limitResults}
                          hideUserResponses={limitResults}
                          hideImprovementFeedback={limitResults}
                        />
                      )
                    })}
                  </Col>
                </Row>
              </div>

              {displaySurvey &&
                <AssessmentSurvey
                  userAssessmentID={userAssessmentResults.userAssessmentID}
                  displaySurvey={displaySurvey}
                  unlockResults={unlockResults}
                />}
            </Col>
          </>}

        <ConfirmationModalWithInput
          confirmTextPlaceholder='Enter Email...'
          isOpen={showShareModal}
          loading={shareResultsLoading}
          onClose={() => setShowShareModal(false)}
          title='Share Results'
          onSubmit={onShareResults}
        />
        <InformationModal
          rawBody
          isOpen={informationModal.isOpen}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => informationModal.onClose ? informationModal.onClose() : resetInfoModal()}
        />
      </Container>
      {!limitResults &&
        <AssessmentReportFooter
          onTalkToAnExpert={onTalkToAnExpert}
          setShowShareModal={setShowShareModal}
        />}
    </>
  )
}

export default withRouter(AssessmentResults)
