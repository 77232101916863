import React, { useState } from 'react'

import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Input, InputGroup, Form } from 'reactstrap'
import Loader from './Loader'

const ConfirmationModalWithInput = ({
  onSubmit,
  onClose,
  isOpen,
  title,
  body,
  confirmColor,
  confirmText,
  confirmTextPlaceholder,
  confirmTextType = 'text',
  loading
}) => {
  const [confirmInput, setConfirmInput] = useState()

  const doSubmit = event => {
    event.preventDefault()
    onSubmit(confirmInput)
  }

  const handleClose = () => {
    setConfirmInput('')
    onClose()
  }

  const closeBtn = <Button className='close' color='none' onClick={handleClose}>&times;</Button>

  return (
    <Modal isOpen={isOpen} onClosed={handleClose} centered>
      <ModalHeader close={closeBtn}>
        {title}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className='text-center'>
          {loading
            ? <Loader size='sm' />
            : (
              <div>
                <p>{body}</p>
                <InputGroup className='col-12' size='m'>
                  <Input
                    type={confirmTextType}
                    placeholder={confirmTextPlaceholder}
                    required
                    maxLength='50'
                    value={confirmInput || ''}
                    onChange={(evt) => setConfirmInput(evt.target.value)}
                  />
                </InputGroup>
              </div>
              )}
        </ModalBody>
        <ModalFooter className='justify-content-between'>
          <Button
            color='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || (confirmText ? (confirmInput || '').toLowerCase() !== (confirmText || '').toLowerCase() : !confirmInput?.length)}
            color={confirmColor || 'primary'}
            type='submit'
          >
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ConfirmationModalWithInput
