import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Col, FormGroup, CustomInput, Label } from 'reactstrap'
import { NotificationToast } from '../../components/tools'
import { assessmentTypes, loaderArea, progressComponentTypes } from '../../utils'
import { StyledLoadingIndicator } from '../../utils/custom'
import { createUserAssessment, getAssessmentFlowQuestions, getActiveAssessmentList } from '../../redux/actions'
import { usePromiseTracker } from 'react-promise-tracker'
import queryString from 'query-string'

const IndustrySector = (props) => {
  const { preferredSector, preferredIndustry } = useSelector(state => state.auth)
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.SELECT_ASSESSMENT })
  const { userID } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [selectedIndustry, setSelectedIndustry] = useState(null)
  const [selectedSector, setSelectedSector] = useState(null)
  const [selectedAssessment, setSelectedAssessment] = useState(null)

  const parsedProps = queryString.parse(props?.location?.search)
  const industry = parsedProps?.industry || null
  const sector = parsedProps?.sector || null
  const assessment = parsedProps?.assessment || null

  const [activeAssessmentList, setActiveAssessmentList] = useState([])

  const fireAssessmentListSuccess = (data) => {
    setActiveAssessmentList(data)
  }

  const fireAssessmentListFailure = () => {
    NotificationToast(true, 'Unable to get available assessments.', false)
  }

  useEffect(() => {
    if (activeAssessmentList?.length === 0) {
      // If industry and sector are present, don't set assessmentType so Primary and Alternate assessments will be returned for selection
      const assessmentType = industry && sector ? '' : 'P'
      const filter = { groupType: 'grouped', assessmentType }
      dispatch(getActiveAssessmentList(filter, fireAssessmentListSuccess, fireAssessmentListFailure))
    }
  }, [dispatch, activeAssessmentList, industry, sector])

  // Set the sector list to the sectors of the selected industry
  const sectorOpts = useMemo(() => {
    if (activeAssessmentList?.length > 0 && selectedIndustry) {
      const indSectors = selectedIndustry?.sectors
      return indSectors
    }
    return []
  }, [activeAssessmentList, selectedIndustry])

  // ----- Create fireSuccess
  const fireCreateSuccess = useCallback((newInfo) => {
    // If the questions for the new assessment were successfully fetched, route to the assessment questions
    const fireQuestionsSuccess = (uaID) => {
      props.history.replace({ pathname: '/assessments/flow', search: `?userAssessment=${uaID}` })
    }

    // -- questions fire failure
    const fireQuestionsFailure = () => {
      NotificationToast(true, 'Unable to fetch current assessment.', false)
    }

    // if we successfully created a new assessment, it return a userAssessmentID and assessmentID
    // use these to fetch the correct set of questions
    const { userAssessmentID: newUA, assessmentID } = newInfo
    if (newUA && assessmentID) {
      dispatch(getAssessmentFlowQuestions(assessmentID, newUA, fireQuestionsSuccess, fireQuestionsFailure))
    }
  }, [dispatch, props])

  // ----- Create fireFailure
  const fireCreateFailure = useCallback(() => {
    NotificationToast(true, 'Unable to create new assessment.', false)
  }, [])

  const allSelected = selectedIndustry?.industryID && selectedSector?.sectorID && selectedAssessment?.assessmentID

  // Create a new assessment
  const handleStartNewAssessment = useCallback(({ industryID, sectorID, assessmentID }) => {
    // Be sure we have industry, sector, and assessment passed into function and all inputs are selected properly before continuing
    if ((!industryID && !sectorID && !assessmentID) && (!allSelected)) {
      return NotificationToast(true, 'Must have industry, sector, and assessment selected to continue.')
    }

    const info = { userID, industryID, sectorID, assessmentID }

    dispatch(createUserAssessment(info, fireCreateSuccess, fireCreateFailure))
  }, [dispatch, fireCreateSuccess, fireCreateFailure, allSelected, userID])

  // Handle the industry selection
  const handleIndustryChange = useCallback((id) => {
    // Reset the sector list
    setSelectedSector(null)

    // find the selected ID in the industry list
    const newIndustry = activeAssessmentList.find(x => x.industryID === parseInt(id))

    // If the industry was found
    if (newIndustry) {
      setSelectedIndustry(newIndustry)
      if (selectedSector) setSelectedSector(null)
      if (selectedAssessment) setSelectedAssessment(null)

      return newIndustry
    } else {
      // reset the selected industry to null
      setSelectedIndustry(null)

      return false
    }
  }, [activeAssessmentList, selectedSector, selectedAssessment])

  // Handle the sector change
  const handleSectorChange = useCallback((id, indSectors) => {
    // find the id in the list of sector opts
    const newSector = indSectors ? indSectors.find(x => x.sectorID === parseInt(id)) : sectorOpts.find(x => x.sectorID === parseInt(id))

    // If the sector was found
    if (newSector) {
      setSelectedSector(newSector)

      /* TEMPORARY: Find and set the sectors primary assessment as the selected assessment since assessment select is temporarily removed - FAV-527 - 7/17/24 */
      const sectorPrimaryAssessment = newSector?.assessments?.find((assessment) => assessment?.assessmentType === assessmentTypes.PRIMARY)
      if (sectorPrimaryAssessment) {
        setSelectedAssessment(sectorPrimaryAssessment)
      } else {
        // If we are unable to find a primary assessment for the sector, reset selected assessment and throw notification to attempt new sector select
        setSelectedAssessment(null)
        NotificationToast(true, 'Unable to get sector information. Please try selecting a different sector.', false)
      }

      return newSector
    } else {
      // reset the selected sector
      setSelectedSector(null)

      return false
    }
  }, [sectorOpts])

  /* TEMPORARY: Temporarily hiding assessment change callback function - FAV-527 - 7/17/24 */
  // const handleAssessmentChange = useCallback((assessmentID, sectAssessments) => {
  //   const newAssessment = sectAssessments ? sectAssessments.find(x => x.assessmentID === assessmentID) : selectedSector?.assessments.find(x => x.assessmentID === assessmentID)

  //   if (newAssessment) {
  //     setSelectedAssessment(newAssessment)

  //     return true
  //   } else {
  //     setSelectedAssessment(null)

  //     return false
  //   }
  // }, [selectedSector])
  /* TEMPORARY: Temporarily hiding assessment change callback function - FAV-527 - 7/17/24 */

  useEffect(() => {
    if (activeAssessmentList?.length && ((preferredIndustry && preferredSector) || (industry && sector)) && (!selectedIndustry && !selectedSector)) {
      if (assessment) {
        handleStartNewAssessment({ industryID: industry, sectorID: sector, assessmentID: assessment })
      } else {
        const newIndustry = handleIndustryChange(industry || preferredIndustry)

        if (newIndustry) {
          handleSectorChange(sector || preferredSector, newIndustry?.sectors, newIndustry?.industryID)
        }
      }

      if (industry || sector || assessment) props.history.replace({ search: '' })
    }
  }, [activeAssessmentList, handleIndustryChange, handleSectorChange, preferredIndustry, preferredSector, selectedIndustry, selectedSector, industry, sector, assessment, props.history, handleStartNewAssessment])

  // reset state on component unmount
  useEffect(() => {
    return () => {
      setSelectedIndustry(null)
      setSelectedSector(null)
      setSelectedAssessment(null)
    }
  }, [])

  return (
    <>
      {promiseInProgress && (!activeAssessmentList?.length || activeAssessmentList?.length === 0) &&
        <StyledLoadingIndicator area={loaderArea.SELECT_ASSESSMENT} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />}

      {!promiseInProgress && (!activeAssessmentList?.length || activeAssessmentList?.length === 0) &&
        <h2 className='mt-3 mb-1'>No selection available.</h2>}

      {!promiseInProgress && activeAssessmentList?.length > 0 &&
        <Form
          className='d-flex flex-column col-12 col-lg-8'
        >
          <div className='row-12 d-flex justify-content-between'>
            <div className='d-flex align-items-start justify-content-start mt-4' />

            {allSelected &&
              <div className='d-flex align-items-start justify-content-end mt-4'>
                <button
                  type='button'
                  onClick={() => { handleStartNewAssessment({ industryID: selectedIndustry?.industryID, sectorID: selectedSector?.sectorID, assessmentID: selectedAssessment?.assessmentID }) }}
                  className='cursor-pointer py-1 px-2 d-flex align-items-center bg-white border-0 outline-none'
                >
                  <span className='mr-3 text-tertiary' style={{ fontWeight: 600 }}>Next</span>
                  <span className='rounded-circle border border-lighter d-flex' style={{ padding: '10px 12px' }}>
                    <FontAwesomeIcon icon={faChevronRight} className='text-tertiary' size='sm' />
                  </span>
                </button>
              </div>}
          </div>

          <Col className='mt-4 row-12 d-flex flex-column justify-content-center'>
            <div>
              <small className='text-primary text-uppercase font-600'>Industry, Sector, and Assessment</small>
              <h2 className='mt-3 mb-1'>Please select your industry, sector, and assessment</h2>

              <FormGroup className='mt-5'>
                <Label className='font-600'>
                  <span>Industry</span>
                  <small className='text-danger ml-1'>*</small>
                </Label>
                <CustomInput
                  id='industry-selector'
                  type='select'
                  required
                  value={selectedIndustry?.industryID || ''}
                  name='industry-selector'
                  onChange={e => {
                    handleIndustryChange(e.target.value)
                  }}
                >
                  <option value=''>Select an Industry</option>
                  {activeAssessmentList.map(industry =>
                    <option key={`industry-${industry.industryID}`} value={industry.industryID}>{industry.industryName}</option>
                  )}
                </CustomInput>
              </FormGroup>

              <FormGroup className='mt-2'>
                <Label className='font-600'>
                  <span>Sector</span>
                  <small className='text-danger ml-1'>*</small>
                </Label>
                <CustomInput
                  id='sector-selector'
                  type='select'
                  required
                  disabled={!sectorOpts.length}
                  value={selectedSector?.sectorID || ''}
                  name='sector-selector'
                  onChange={e => handleSectorChange(e.target.value)}
                >
                  <option value=''>Select a Sector</option>
                  {sectorOpts.map(sector =>
                    <option key={`sector-${sector.sectorID}`} value={sector.sectorID}>{sector.sectorName}</option>
                  )}
                </CustomInput>
              </FormGroup>

              {/* TEMPORARY: Temporarily hiding the assessment select - FAV-527 - 7/17/24 */}
              {/* <FormGroup className='mt-2'>
                <Label className='font-600'>
                  <span>Assessment</span>
                  <small className='text-danger ml-1'>*</small>
                </Label>
                <CustomInput
                  id='assessment-selector'
                  type='select'
                  required
                  disabled={!selectedSector?.assessments}
                  value={selectedAssessment?.assessmentID || ''}
                  name='assessment-selector'
                  onChange={e => handleAssessmentChange(e.target.value)}
                >
                  <option value=''>Select an Assessment</option>
                  {selectedSector?.assessments?.length > 0 && selectedSector.assessments.map(assessment =>
                    <option key={`assessment-${assessment.assessmentID}`} value={assessment.assessmentID}>{assessment.title}</option>
                  )}
                </CustomInput>
              </FormGroup> */}
              {/* TEMPORARY: Temporarily hiding the assessment select - FAV-527 - 7/17/24 */}

            </div>
          </Col>
        </Form>}

    </>
  )
}

export default withRouter(IndustrySector)
