import React from 'react'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col } from 'reactstrap'
import { assessmentReportSidebarClasses } from '../../utils/bootstrapClassNames'

const SCORE_LIMIT = 71

const FarLeftBox = ({ assessmentScore = 0, onTalkToAnExpert = () => { } }) => {
  const getFarLeftBoxHeader = () => {
    if (assessmentScore < SCORE_LIMIT) {
      return 'Feeling Overwhelmed?'
    }
    return 'Great Score'
  }

  const getFarLeftBoxText = () => {
    if (assessmentScore < SCORE_LIMIT) {
      return 'There might be a variety of areas for improvement, but we’re here to help! Our team can provide insight into the best places to focus your energy to improve your Investment Grade.'
    }
    return 'Your Company appears to be doing really well. Our team of experts is happy to discuss your results and potential opportunities to harvest the reward of your investment.'
  }

  return (
    <Col className={assessmentReportSidebarClasses.farLeftBoxContainer}>
      <span className='font-600'>{getFarLeftBoxHeader()}</span>
      <p className='small mt-2'>{getFarLeftBoxText()}</p>
      <div className={assessmentReportSidebarClasses.farLeftBoxSubContainer}>
        <Button onClick={() => onTalkToAnExpert()} color='primary' className={assessmentReportSidebarClasses.farLeftBoxButton}>
          <FontAwesomeIcon icon={faShareSquare} className='text-white' size='lg' />
          <small className='ml-2'>Discuss Results with an Expert</small>
        </Button>
      </div>
    </Col>
  )
}

export default FarLeftBox
