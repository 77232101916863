/*
NOTE: This file is used to store long bootstrap class names in objects instead of putting them inline.
Currently only used for the assessment results / report components (along with their subcomponents), but more components can be included here.
*/

// ASSESSMENT RESULTS / REPORT COMPONENT AND SUBCOMPONENT CLASSES
export const assessmentReportClasses = {
  flexColumn: 'd-flex flex-column',
  sectionsContainer: 'border-top pt-5 px-2 mb-2'
}

export const assessmentReportHeaderClasses = {
  mainContainer: 'd-flex flex-column justify-content-center align-items-center border-bottom pb-5',
  headerButtonsContainer: 'd-flex justify-content-between align-items-center',
  pdfButton: 'rounded d-flex justify-content-between align-items-center',
  shareResultsButton: 'ml-2 border-primary rounded text-primary d-flex justify-content-between align-items-center',
  headerInfoContainer: 'd-flex flex-column flex-sm-row mt-4 justify-content-around col-12 col-lg-5',
  headerInfoItem: 'd-flex flex-column justify-content-center align-items-center'

}
export const assessmentReportGradingClasses = {
  gradingContainer: 'border-top pt-3 mb-4'
}

export const assessmentReportSidebarClasses = {
  sidebarContainer: 'col-12 col-lg-3',
  sidebarSubContainer: 'sticky-top pb-2',
  sidebarFormGroup: 'col-12 px-0 cursor-pointer',
  sidebarLabel: 'font-weight-300 p-2 border rounded form-check-label col-12 d-flex align-items-center justify-content-between',
  sidebarSubLabel: 'text-center min-width-50 rounded-pill px-2 border text-white',
  fabContainer: 'fab-button d-flex align-items-center justify-content-center d-lg-none d-xl-none bg-primary shadow',
  farLeftBoxContainer: 'd-flex flex-column p-3 border rounded bg-white mt-2',
  farLeftBoxSubContainer: 'd-flex justify-content-start',
  farLeftBoxButton: 'rounded d-flex align-items-center'
}

export const assessmentSectionResultsClasses = {
  sectionContainer: 'd-flex justify-content-between align-items-center bg-darkerblue text-white py-2 px-2',
  cardTitleContainer: 'd-flex justify-content-end align-items-center py-1',
  cardTitleScore: 'text-white rounded-pill px-4 py-1 text-center border',
  cardBodyContainer: 'd-flex flex-column flex-sm-row flex-md-row',
  improveScoreContainer: 'd-flex flex-column justify-content-start align-items-start col-xs-12 col-sm-7 col-md-8',
  contactContainer: 'd-flex flex-column justify-content-between col-xs-12 col-sm-5 col-md-4 my-2 p-3 border rounded bg-white',
  contactButton: 'col-6 border-primary rounded text-primary',
  responseContainer: 'd-flex flex-column mt-2',
  questionContainer: 'd-flex p-3 border rounded bg-white mb-3 flex-column flex-sm-row flex-md-row',
  questionAnswerContainer: 'd-flex flex-column justify-content-start align-items-start col-xs-12 col-sm-4 col-md-5',
  questionFeedbackContainer: 'd-flex flex-column justify-content-start align-items-start col-xs-12 col-sm-8 col-md-7'
}

export const assessmentReportFooterClasses = {
  reportFooter: 'report-footer bg-secondary d-flex',
  reportFooterContainer: 'd-flex flex-column col-12 align-items-center justify-content-center',
  buttonContainer: 'd-flex flex-column flex-md-row align-items-center justify-content-center',
  shareResultsButton: 'py-3 py-md-4 px-5 rounded-0',
  talkWithAnAdvisorButton: 'mx-4 my-3 border-primary py-3 py-md-4 px-3 px-lg-5 rounded-0'
}
