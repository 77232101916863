import React from 'react'
import { Button } from 'reactstrap'
import { assessmentReportFooterClasses } from '../../utils/bootstrapClassNames'

const AssessmentReportFooter = (props) => {
  const { onTalkToAnExpert = () => { }, setShowShareModal = () => { } } = props
  return (
    <div className={assessmentReportFooterClasses.reportFooter}>
      <div className={assessmentReportFooterClasses.reportFooterContainer}>
        <h1 className='mb-4 text-white'>Next Steps</h1>
        <div className={assessmentReportFooterClasses.buttonContainer}>
          <Button size='lg' color='primary' className={assessmentReportFooterClasses.shareResultsButton} onClick={() => setShowShareModal(true)}>
            Share Results
          </Button>
          <Button size='lg' className={assessmentReportFooterClasses.talkWithAnAdvisorButton} onClick={() => onTalkToAnExpert()}>
            Talk With an Advisor
          </Button>
          {/* temporarily removing until further discussion with tom can decide how this should work */}
          {/* <Button size='lg' color='primary' className='py-3 py-md-4 px-5 rounded-0'>
            Refer a Friend
          </Button> */}
        </div>
      </div>
    </div>
  )
}

export default AssessmentReportFooter
