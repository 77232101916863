import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Dialog, DialogActions, DialogTitle, DialogContent,
  Grid, Typography, IconButton, Button, useTheme, RadioGroup, FormControlLabel,
  Radio, Select, MenuItem, OutlinedInput, Card, CardContent, Divider, Tooltip
} from '@mui/material'

import { CloseRounded, Cancel } from '@mui/icons-material'

import { usePromiseTracker } from 'react-promise-tracker'
import { loaderArea, progressComponentTypes, newAssessmentOptions } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'
import { getAssessmentList } from '../../../redux/actions'

const toggleStates = {
  OPEN: 'open',
  CLOSE: 'close'
}

// Form to create a new or duplicated assessment
// Rendered in AssessmentList.js
const AddAssessmentModal = (props) => {
  // all props generated and passed in from BuilderAssessmentTable.js
  const {
    addAssessmentModalOpen = false, addAssessmentView, setAddAssessmentView = () => { }, handleCloseAddAssessmentModal = () => { }, addAssessmentForm = {},
    setAddAssessmentForm = () => { }, errors = {}, setErrors = () => { }, handleAddAssessment = () => { },
    defaultErrors, defaultAddAssessmentForm = {}
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()

  const { industryList: { industries = [] } } = useSelector(state => state.industry)
  const { assessmentList: { totalAssessments = 0 } } = useSelector(state => state.assessment)
  const { promiseInProgress: builderListPromise } = usePromiseTracker({ area: loaderArea.BUILDER_ASSESSMENT_LIST })
  const { promiseInProgress: addAssessmentPromise } = usePromiseTracker({ area: loaderArea.CREATE_ASSESSMENT })
  const { promiseInProgress: dupeAssessmentPromise } = usePromiseTracker({ area: loaderArea.DUPLICATE_ASSESSMENTS })

  const [createNewSector, setCreateNewSector] = useState(false)

  const commonSelectProps = {
    displayEmpty: true,
    variant: 'outlined',
    size: 'small',
    defaultValue: '',
    style: { padding: 0, marginTop: '0.2em' }
  }

  const handleBack = () => {
    setAddAssessmentView('')
    setAddAssessmentForm({ ...defaultAddAssessmentForm })
    setErrors({ ...defaultErrors })
    setCreateNewSector(false)
  }

  const [assessmentsForDuplication, setAssessmentsForDuplication] = useState([])

  const fetchSuccess = (data = []) => {
    setAssessmentsForDuplication(data)
  }

  const handleSetView = (e) => {
    e.preventDefault()
    if (e.target.value === newAssessmentOptions.DUPLICATE_ASSESSMENT) {
      dispatch(getAssessmentList({ sortCount: totalAssessments, noRedux: true }, fetchSuccess))
    }
    setAddAssessmentView(e.target.value)
  }
  const handleCreateNewSector = (toggleState) => {
    if (toggleState === toggleStates.OPEN) {
      setAddAssessmentForm({ ...addAssessmentForm, existingSector: { sectorID: '', display: '' } })
      setCreateNewSector(true)
    } else {
      setAddAssessmentForm({ ...addAssessmentForm, newSectorName: '' })
      setCreateNewSector(false)
    }
  }

  const sectorOpts = useMemo(() => {
    if (industries && addAssessmentForm?.existingIndustry.industryID) {
      const selectedIndustry = industries.find(industry => industry.id === addAssessmentForm?.existingIndustry.industryID)

      const indSectors = selectedIndustry?.sectors
      return indSectors
    }
    return []
  }, [addAssessmentForm?.existingIndustry.industryID, industries])

  const renderModalContentView = (view) => {
    switch (view) {
      case newAssessmentOptions.CREATE_NEW_ASSESSMENT: {
        return (
          <>
            <DialogContent dividers>
              <Grid container direction='column' spacing={2}>
                {/* Industry */}
                <Grid item container direction='column'>
                  <Typography gutterBottom variant='body1'>Industry</Typography>
                  <Grid item container direction='row' alignItems='center' spacing={1}>
                    <Grid item xs={8}>
                      <Select
                        {...commonSelectProps}
                        disabled={Boolean(!industries || !industries.length)}
                        fullWidth
                        value={addAssessmentForm?.existingIndustry?.display || ''}
                        onChange={(e, child) => setAddAssessmentForm({ ...addAssessmentForm, existingIndustry: { industryID: child.props.id, display: e.target.value }, existingSector: { sectorID: '', display: '' } })}
                      >
                        <MenuItem value='' disabled>Select an Industry...</MenuItem>
                        {industries?.map(industry => {
                          return (
                            <MenuItem key={`industry-modal-${industry.id}`} id={industry.id} value={industry.name}>{industry.name}</MenuItem>
                          )
                        })}
                      </Select>
                    </Grid>
                    {Boolean(addAssessmentForm?.existingIndustry?.display) &&
                      <Grid item>
                        <IconButton style={{ padding: '0px' }} onClick={() => setAddAssessmentForm({ ...addAssessmentForm, existingIndustry: { industryID: '', display: '' }, existingSector: { sectorID: '', display: '' } })}>
                          <Cancel sx={{ color: theme.palette.blue.main }} />
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item container direction='column' spacing={1}>
                  <Grid item xs={8}>
                    <Typography gutterBottom variant='body1'>Sector</Typography>
                    <Select
                      {...commonSelectProps}
                      disabled={!addAssessmentForm?.existingIndustry?.display || createNewSector}
                      fullWidth
                      value={addAssessmentForm?.existingSector?.display || ''}
                      onChange={(e, child) => setAddAssessmentForm({ ...addAssessmentForm, existingSector: { sectorID: child.props.id, display: e.target.value } })}
                    >
                      <MenuItem value='' disabled>Select a Sector...</MenuItem>
                      {sectorOpts?.map(sector => {
                        return (
                          <MenuItem key={`sector-modal-${sector.id}`} id={sector.id} value={sector.name}>{sector.name}</MenuItem>
                        )
                      })}
                    </Select>
                    {/* NOTE: this will need to be removed once we allow for multiple primaries */}
                    {addAssessmentForm?.existingSector?.sectorID && <Typography gutterBottom variant='caption'><span style={{ color: 'red', fontWeight: 600 }}>*</span> Selecting an existing sector will create an alternate assessment</Typography>}
                  </Grid>
                  <Grid item>
                    <Typography fontWeight={600}>or</Typography>
                  </Grid>
                  {!createNewSector ?
                    (
                      <Grid item container direction='column'>
                        <Grid item>
                          <Button variant='outlined' onClick={() => handleCreateNewSector(toggleStates.OPEN)}>Create New Sector</Button>
                        </Grid>
                      </Grid>) : (
                      <Grid item container direction='column'>
                        <Grid item container direction='row' alignItems='center'>
                          <Grid item xs={9}>
                            <OutlinedInput
                              fullWidth
                              margin='dense'
                              size='small'
                              inputProps={{
                                maxLength: 255
                              }}
                              value={addAssessmentForm?.newSectorName || ''}
                              placeholder='Type a new sector name here...'
                              onChange={(e) => setAddAssessmentForm({ ...addAssessmentForm, newSectorName: e.target.value })}
                            />
                          </Grid>
                          <Grid item>
                            <Button sx={{ padding: 0, marginLeft: '0.2em' }} onClick={() => handleCreateNewSector(toggleStates.CLOSE)}>
                              <Typography variant='body1' fontWeight={600}>
                                Cancel
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${addAssessmentForm?.newSectorName?.length || 0}/255`}</Typography>
                        </Grid>
                      </Grid>)}
                </Grid>
              </Grid>
            </DialogContent >
          </>
        )
      }
      case newAssessmentOptions.DUPLICATE_ASSESSMENT: {
        return (
          <>
            <DialogContent dividers>
              <Grid container direction='column' spacing={2}>
                {builderListPromise ?
                  (<Grid item container direction='column' justifyContent='center' alignItems='center'>
                    <StyledLoadingIndicator
                      area={loaderArea.BUILDER_ASSESSMENT_LIST}
                      loaderType={progressComponentTypes.CIRCULAR_PROGRESS}
                      style={{ color: theme.palette.primary }}
                      size={35}
                    />
                  </Grid>) : (
                    <Grid item container direction='column'>
                      <Typography gutterBottom variant='body1'>Assessment</Typography>
                      <Grid item container direction='row' alignItems='center' spacing={1}>
                        <Grid item xs={10}>
                          <Select
                            {...commonSelectProps}
                            disabled={Boolean(!assessmentsForDuplication || !assessmentsForDuplication.length)}
                            fullWidth
                            value={addAssessmentForm?.assessment.display || ''}
                            onChange={(e, child) => {
                              setAddAssessmentForm(
                                {
                                  ...addAssessmentForm,
                                  assessment: {
                                    assessmentID: child.props.id, display: e.target.value, industryName: child.props.industryname, questionCount: child.props.questioncount,
                                    isActive: child.props.isactive
                                  }
                                }
                              )
                            }}
                          >
                            <MenuItem value='' disabled>Select an assessment to duplicate...</MenuItem>
                            {assessmentsForDuplication?.map(assessment => {
                              return (
                                <MenuItem
                                  key={`duplicate-assessment-modal-${assessment?.assessmentID}`}
                                  id={assessment?.assessmentID}
                                  value={assessment?.assessmentTitle}
                                  industryname={assessment?.industryName}
                                  questioncount={assessment?.questionCount}
                                  isactive={assessment?.isActive}
                                >
                                  {assessment?.assessmentTitle}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                        {Boolean(addAssessmentForm?.assessment?.display) &&
                          <Grid item>
                            <IconButton style={{ padding: '0px' }} onClick={() => setAddAssessmentForm({ ...addAssessmentForm, assessment: '' })}>
                              <Cancel sx={{ color: theme.palette.blue.main }} />
                            </IconButton>
                          </Grid>
                        }
                      </Grid>
                    </Grid>)}

                {addAssessmentForm?.assessment.assessmentID &&
                  <Grid item container direction='column' justifyContent='center'>
                    <Typography gutterBottom variant='body1'>Selected Assessment Information</Typography>
                    <Grid item>
                      <Card variant='outlined'>
                        <CardContent>
                          <Grid item container direction='row' alignItems='center' spacing={3}>

                            <Grid item container direction='column' xs={4}>
                              <Typography variant='caption'>Current Status</Typography>
                              <Typography variant='body1' fontWeight={600}>{Boolean(addAssessmentForm?.assessment.isActive) ? 'Active' : 'Inactive'}</Typography>
                            </Grid>

                            <Grid item container direction='column' xs={4}>
                              <Typography variant='caption'>Industry Name</Typography>
                              <Tooltip arrow title={addAssessmentForm?.assessment.industryName &&
                                addAssessmentForm?.assessment.industryName.length && addAssessmentForm?.assessment.industryName.length > 20 ?
                                addAssessmentForm?.assessment.industryName : ''}>

                                <Typography variant='body1' fontWeight={600}>
                                  {addAssessmentForm?.assessment.industryName && addAssessmentForm?.assessment.industryName.length && addAssessmentForm?.assessment.industryName.length > 20 ?
                                    `${addAssessmentForm?.assessment.industryName.substring(0, 20)}...` : addAssessmentForm?.assessment.industryName}
                                </Typography>
                              </Tooltip>
                            </Grid>

                            <Grid item container direction='column' xs={4}>
                              <Typography variant='caption'>Total Questions</Typography>
                              <Typography variant='body1' fontWeight={600}>{addAssessmentForm?.assessment.questionCount}</Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                }

                <Grid item>
                  <Divider />
                </Grid>
                {/* Industry */}
                <Grid item container direction='column'>
                  <Typography gutterBottom variant='body1'>Industry</Typography>
                  <Grid item container direction='row' alignItems='center' spacing={1}>
                    <Grid item xs={8}>
                      <Select
                        {...commonSelectProps}
                        disabled={Boolean(!industries || !industries.length)}
                        fullWidth
                        value={addAssessmentForm?.existingIndustry?.display || ''}
                        onChange={(e, child) => setAddAssessmentForm({ ...addAssessmentForm, existingIndustry: { industryID: child.props.id, display: e.target.value }, existingSector: { sectorID: '', display: '' } })}
                      >
                        <MenuItem value='' disabled>Select an Industry...</MenuItem>
                        {industries?.map(industry => {
                          return (
                            <MenuItem key={`industry-modal-${industry.id}`} id={industry.id} value={industry.name}>{industry.name}</MenuItem>
                          )
                        })}
                      </Select>
                    </Grid>
                    {Boolean(addAssessmentForm?.existingIndustry?.display) &&
                      <Grid item>
                        <IconButton style={{ padding: '0px' }} onClick={() => setAddAssessmentForm({ ...addAssessmentForm, existingIndustry: '', existingSector: { sectorID: '', display: '' } })}>
                          <Cancel sx={{ color: theme.palette.blue.main }} />
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                </Grid>

                {/* New Sector Name */}
                <Grid item container direction='column' spacing={1}>
                  <Grid item xs={8}>
                    <Typography gutterBottom variant='body1'>Sector</Typography>
                    <Select
                      {...commonSelectProps}
                      disabled={!addAssessmentForm?.existingIndustry?.display || createNewSector}
                      fullWidth
                      value={addAssessmentForm?.existingSector?.display || ''}
                      onChange={(e, child) => setAddAssessmentForm({ ...addAssessmentForm, existingSector: { sectorID: child.props.id, display: e.target.value } })}
                    >
                      <MenuItem value='' disabled>Select a Sector...</MenuItem>
                      {sectorOpts?.map(sector => {
                        return (
                          <MenuItem key={`sector-modal-${sector.id}`} id={sector.id} value={sector.name}>{sector.name}</MenuItem>
                        )
                      })}
                    </Select>
                    {/* NOTE: this will need to be removed once we allow for multiple primaries */}
                    {addAssessmentForm?.existingSector?.sectorID && <Typography gutterBottom variant='caption'><span style={{ color: 'red', fontWeight: 600 }}>*</span> Selecting an existing sector will create an alternate assessment</Typography>}
                  </Grid>
                  <Grid item>
                    <Typography fontWeight={600}>or</Typography>
                  </Grid>
                  {!createNewSector ?
                    (
                      <Grid item container direction='column'>
                        <Grid item>
                          <Button variant='outlined' onClick={() => handleCreateNewSector(toggleStates.OPEN)}>Create New Sector</Button>
                        </Grid>
                      </Grid>) : (
                      <Grid item container direction='column'>
                        <Grid item container direction='row' alignItems='center'>
                          <Grid item xs={9}>
                            <OutlinedInput
                              fullWidth
                              margin='dense'
                              size='small'
                              inputProps={{
                                maxLength: 255
                              }}
                              value={addAssessmentForm?.newSectorName || ''}
                              placeholder='Type a new sector name here...'
                              onChange={(e) => setAddAssessmentForm({ ...addAssessmentForm, newSectorName: e.target.value })}
                            />
                          </Grid>
                          <Grid item>
                            <Button sx={{ padding: 0, marginLeft: '0.2em' }} onClick={() => handleCreateNewSector(toggleStates.CLOSE)}>
                              <Typography variant='body1' fontWeight={600}>
                                Cancel
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${addAssessmentForm?.newSectorName?.length || 0}/255`}</Typography>
                        </Grid>
                      </Grid>)}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )
      }
      default: return (
        <>
          <DialogContent dividers>
            {/* New Sector Name */}
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1' fontWeight={600}>Do you want to create a new assessment or duplicate an existing one?</Typography>
              <Typography gutterBottom variant='caption' sx={{ marginTop: '0.5em' }}><span style={{ color: 'red', fontSize: '1.2em' }}>*</span> Select an answer:</Typography>

              <RadioGroup onChange={(e) => { handleSetView(e) }}>
                <FormControlLabel defaultChecked='' value={newAssessmentOptions.CREATE_NEW_ASSESSMENT} control={<Radio size='small' checked={Boolean(addAssessmentView && addAssessmentView === newAssessmentOptions.CREATE_NEW_ASSESSMENT)} />} label='Create New Assessment' />
                <FormControlLabel defaultChecked='' value={newAssessmentOptions.DUPLICATE_ASSESSMENT} control={<Radio size='small' checked={Boolean(addAssessmentView && addAssessmentView === newAssessmentOptions.DUPLICATE_ASSESSMENT)} />} label='Duplicate Existing Assessment' />
              </RadioGroup>
            </Grid>
          </DialogContent>
        </>
      )
    }
  }

  return (
    <Dialog
      open={addAssessmentModalOpen}
      onClose={() => handleCloseAddAssessmentModal()}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h5'>
              {!addAssessmentView ? 'Add Assessment' : addAssessmentView === newAssessmentOptions.CREATE_NEW_ASSESSMENT ?
                'Create New Assessment' : 'Duplicate Assessment'}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={() => handleCloseAddAssessmentModal()}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {renderModalContentView(addAssessmentView)}

      <DialogActions>
        <Grid container direction='column'>
          {errors?.invalidSectorName &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter a valid sector name.</Typography>}
          {errors?.invalidIndustryName &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter a valid sector name.</Typography>}
          {errors?.existingSectorName &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Sector name already exists.</Typography>}
          {errors?.requiredInformation &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter all required information.</Typography>}
          {errors?.failedAction &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>{`* ${errors?.failedAction || 'Unable to perform action.'}`}</Typography>}
          <Grid container item direction='row' justifyContent={!addAssessmentView ? 'center' : 'space-between'} alignItems='center'>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em .3em .5em 1em',
                  fontWeight: '400'
                }}
                onClick={() => handleCloseAddAssessmentModal()}
              >
                Cancel
              </Button>
            </Grid>

            {addAssessmentView && (addAssessmentView === newAssessmentOptions.CREATE_NEW_ASSESSMENT || addAssessmentView === newAssessmentOptions.DUPLICATE_ASSESSMENT) &&
              <>
                <Grid item>
                  <Button
                    variant='text'
                    style={{
                      margin: '.5em',
                      fontWeight: '400'
                    }}
                    onClick={handleBack}>
                    Back
                  </Button>

                  <Button
                    variant='contained'
                    style={{
                      margin: '.5em 1em',
                      fontWeight: '400'
                    }}
                    onClick={() => handleAddAssessment(addAssessmentView)}>
                    {Boolean(addAssessmentPromise || dupeAssessmentPromise) ? <StyledLoadingIndicator area={loaderArea.CREATE_ASSESSMENT} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} /> : 'Save'}
                  </Button>
                </Grid>
              </>}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default AddAssessmentModal
