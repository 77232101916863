import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'

import log from 'loglevel'
import { loaderArea } from '../../utils'

const apiPath = process.env.REACT_APP_API_URL

// Get PDF version of assessment report and return as download
export const getAssessmentReportPDF = (reportID, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/report/${reportID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()
        return data
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get report pdf.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Share PDF version of assessment report with provided email address
export const shareAssessmentReportPDF = (reportID, emailAddress, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const body = { email: emailAddress }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/report/${reportID}/share`, options), loaderArea.SHARE_ASSESSMENT_REPORT)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
      } else {
        fireFailure()

        log.info('Failed to share report pdf.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
