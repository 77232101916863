import React from 'react'
import { withRouter } from 'react-router-dom'
import GradingChart from './GradingChart'
import { assessmentReportGradingClasses } from '../../utils/bootstrapClassNames'

const AssessmentReportGrading = (props) => {
  return (
    <div className={assessmentReportGradingClasses.gradingContainer}>
      <h4 className='text-center'>Grading by Section</h4>
      <GradingChart />
    </div>
  )
}

export default withRouter(AssessmentReportGrading)
