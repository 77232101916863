import React from 'react'
import { withRouter } from 'react-router-dom'
import { FormGroup, Label, Input } from 'reactstrap'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { utils } from '../../utils/utils'

import FarLeftBox from './FarLeftBox'
import FloatingActionButton from './FloatingActionButton'
import { assessmentReportSidebarClasses } from '../../utils/bootstrapClassNames'

const AssessmentReportSidebar = (props) => {
  const { pageAssessment, toggleSectionID, setToggleSectionID = () => { }, onTalkToAnExpert = () => { }, disableSelection = false, disableContact = false } = props

  // Open a section and then scroll to it. The timeout is there to prevent scrolling issues that occur when a collapsed section is opened.
  const scrollToAndOpenSection = (sectionID) => {
    setToggleSectionID(sectionID)
    const sectionElement = document.getElementById(`section-${sectionID}`)

    setTimeout(() => {
      if (sectionElement) {
        sectionElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 250)
  }
  return (
    <div className={assessmentReportSidebarClasses.sidebarContainer}>
      <div className={assessmentReportSidebarClasses.sidebarSubContainer} style={{ top: '20px', overflowY: 'auto' }}>
        <h4>Sections</h4>
        {pageAssessment?.sectionScores?.filter(obj => Boolean(obj.sectionScore || obj.sectionScore === 0))
          .map(section => {
            const { sectionID, shortName, sectionScore } = section
            const active = Boolean(toggleSectionID === sectionID && !disableSelection)
            return (
              <FormGroup check className={assessmentReportSidebarClasses.sidebarFormGroup} key={`report-section-${sectionID}-${shortName}`} style={{ marginTop: '.2em' }}>
                <Label check className={`${active ? 'text-primary border-primary cursor-pointer' : ''} ${assessmentReportSidebarClasses.sidebarLabel}`}>
                  <div {...!disableSelection && { onClick: () => scrollToAndOpenSection(sectionID) }}>
                    <Input
                      required
                      className='ml-0'
                      type='radio'
                      name='sectionMC'
                      defaultChecked={active}
                    />
                    <FontAwesomeIcon icon={faCheckCircle} className={`text-${active ? 'primary' : 'white'}`} size='lg' />
                    <span className='ml-2'>{shortName}</span>
                  </div>
                  <small className={`${assessmentReportSidebarClasses.sidebarSubLabel} border-${utils.getScoreBorderColor(sectionScore * 100)}`} style={{ backgroundColor: utils.getScoreBgColor(sectionScore * 100) }}>{`${Math.round(Math.max(sectionScore, 0) * 100)}%`}</small>
                </Label>
              </FormGroup>
            )
          })}
        {!disableContact &&
          <FarLeftBox
            assessmentScore={pageAssessment?.assessmentScore}
            onTalkToAnExpert={onTalkToAnExpert}
          />}
        <FloatingActionButton />
      </div>
    </div>
  )
}

export default withRouter(AssessmentReportSidebar)
